import {
    AccessData,
    DefaultAccessorFactory,
    EChartsView,
    EChartsViewConfig,
    EChartsViewFactory,
    EvolutiveTestNumberAccessor,
    EvolutiveTestNumberAccessorConfig,
    EvolutiveTestSeries2DAccessor,
    EvolutiveTestSeries2DAccessorConfig,
    QueuedAccessor,
    QueuedAccessorConfig,
    TestAccessorFactory,
    TpzApplication,
    TpzDesktopConfig,
    TpzWindow,
    TpzWindowConfig,
    TpzWindowFactory,
    TpzApplicationCommander,
    TpzDesktop,
    AccessorType,
    TpzApplicationTypes
} from 'topaz';
import { EChartOption } from 'echarts';
import { SPAApplication } from '../spa-client';

export function createChartDesktop(app: TpzApplication): TpzDesktopConfig {
    app.getFactoryManager().registerItemFactory(new TpzWindowFactory(app));
    app.getFactoryManager().registerItemFactory(new EChartsViewFactory(app));
    app.getFactoryManager().registerItemFactory(new TestAccessorFactory(app));
    app.getFactoryManager().registerItemFactory(new DefaultAccessorFactory(app));

    let numberAccessorChart1Config: EvolutiveTestNumberAccessorConfig = {
        id: 'number-accessor-chart-1',
        type: AccessorType.NUMBER_EVOLUTIVE_TEST_ACCESSOR_TYPE,
        dataType: AccessData.NUMBER_DATATYPE,
        exportedValue: '5',
        delai: 10,
        increment: 2,
        jitterFactor: 0.2,
        minValue: 0,
        maxValue: 100
    };
    TpzApplicationCommander.registerItem(app, numberAccessorChart1Config);

    let queuedAccessorChart1Config: QueuedAccessorConfig<number> = {
        id: 'number-accessor-queued-chart-1',
        type: AccessorType.QUEUED_ACCESSOR_TYPE,
        dataType: AccessData.NUMBER_ARRAY_DATATYPE,
        accessorIds: [numberAccessorChart1Config.id],
        queueSize: 50
    };
    TpzApplicationCommander.registerItem(app, queuedAccessorChart1Config);

    let number2DAccessorConfig: EvolutiveTestSeries2DAccessorConfig = {
        id: 'number2D-accessor',
        type: AccessorType.SERIES2D_TEST_EVOLUTIVE_ACCESSOR_TYPE,
        dataType: AccessData.NUMBER2D_ARRAY_DATATYPE,
        exportedValue: JSON.stringify(Array.from(Array(100).keys()).map((x) => [x, x])),
        incrementX: 0.001,
        incrementY: 0.001,
        minValueX: 0,
        maxValueX: 100,
        minValueY: 0,
        maxValueY: 100,
        delai: 250,
        scaleFactorX: 0.001,
        scaleFactorY: 0.001
    };
    TpzApplicationCommander.registerItem(app, number2DAccessorConfig);

    // ECharts view
    let chart1ViewConfig: EChartsViewConfig = {
        id: 'chart-view-1',
        type: EChartsView.ECHARTS_VIEW_TYPE,
        css: ['css/echarts.css'],
        chartOptions: {
            animation: false,
            title: {
                text: 'Simple bar chart sample'
            },
            xAxis: [
                {
                    type: 'category'
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    name: 'Y',
                    max: 100,
                    min: 0,
                    boundaryGap: [0.2, 0.2]
                }
            ],
            series: [
                {
                    name: 'Series1',
                    type: 'bar',
                    data: null,
                    areaStyle: {
                        color: '#8ec6ad'
                    }
                }
            ]
        },
        accessorIds: [queuedAccessorChart1Config.id]
    };
    TpzApplicationCommander.registerItem(app, chart1ViewConfig);

    // ECharts view
    let chart2ViewConfig: EChartsViewConfig = {
        id: 'chart-view-2',
        type: EChartsView.ECHARTS_VIEW_TYPE,
        css: ['css/echarts.css'],
        chartOptions: {
            animation: false,
            title: {
                text: 'Simple line chart sample'
            },
            xAxis: [
                {
                    type: 'category'
                }
            ],
            yAxis: [
                {
                    max: 100,
                    min: 0,
                    boundaryGap: [0.2, 0.2]
                }
            ],
            series: [
                {
                    name: 'Series1',
                    type: 'line',
                    lineStyle: {
                        color: 'green',
                        width: 2,
                        type: 'dashed'
                    },
                    data: null
                }
            ]
        },
        accessorIds: [queuedAccessorChart1Config.id]
    };
    TpzApplicationCommander.registerItem(app, chart2ViewConfig);

    // ECharts view
    let chart3ViewConfig: EChartsViewConfig = {
        id: 'chart-view-3',
        type: EChartsView.ECHARTS_VIEW_TYPE,
        css: ['css/echarts.css'],
        chartOptions: {
            backgroundColor: '#EDF',
            animation: false,
            title: {
                text: 'Pie Chart'
            },

            series: [
                {
                    name: 'Series1',
                    type: 'pie',
                    radius: [20, 100],
                    data: null
                }
            ]
        },
        accessorIds: [queuedAccessorChart1Config.id]
    };
    TpzApplicationCommander.registerItem(app, chart3ViewConfig);

    let echartOptions: EChartOption = {
        backgroundColor: '#FFF',
        title: {
            text: '1990'
        },
        legend: {
            right: 10,
            data: ['1990']
        },
        xAxis: {
            min: 0,
            max: 100,
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            },
            scale: true
        },
        series: [
            {
                name: '1990',
                type: 'scatter',
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(120, 36, 50, 0.5)',
                    shadowOffsetY: 2,
                    color: '#EA4'
                }
            }
        ]
    };

    // ECharts view
    let chart4ViewConfig: EChartsViewConfig = {
        id: 'chart-view-4',
        type: EChartsView.ECHARTS_VIEW_TYPE,
        css: ['css/echarts.css'],
        chartOptions: echartOptions,
        accessorIds: [number2DAccessorConfig.id]
    };
    TpzApplicationCommander.registerItem(app, chart4ViewConfig);

    // Window for Chart View
    let chart1WindowConfig: TpzWindowConfig = {
        id: 'chart-window-1',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [chart1ViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 50px 50px',
            panelSize: '400px 200px'
        },
        title: 'ECharts'
    };
    TpzApplicationCommander.registerItem(app, chart1WindowConfig);

    // Window for Chart View
    let chartWindow2Config: TpzWindowConfig = {
        id: 'chart-window-2',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [chart2ViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 460px 53.6364px',
            panelSize: '400px 200px'
        },
        title: 'ECharts'
    };
    TpzApplicationCommander.registerItem(app, chartWindow2Config);

    // Window for Chart View
    let chart3WindowConfig: TpzWindowConfig = {
        id: 'chart-window-3',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [chart3ViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 50px 253.6364px',
            panelSize: '400px 200px'
        },
        title: 'ECharts'
    };
    TpzApplicationCommander.registerItem(app, chart3WindowConfig);

    // Window for Chart View
    let chart4WindowConfig: TpzWindowConfig = {
        id: 'chart-window-4',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [chart4ViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 460px 253.6364px',
            panelSize: '400px 200px'
        },
        title: 'ECharts'
    };
    TpzApplicationCommander.registerItem(app, chart4WindowConfig);

    // Complete desktop
    const desktopConfig: TpzDesktopConfig = {
        id: SPAApplication.CHART_DESKTOP_ID.id,
        type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
        name: SPAApplication.CHART_DESKTOP_ID.name,
        childrenIds: [chart1WindowConfig.id, chartWindow2Config.id, chart3WindowConfig.id, chart4WindowConfig.id]
    };
    return desktopConfig;

    // let menu: SimpleMenuPlugin = app.getPluginManager().getPluginById(SmisApplication.MAIN_MENU_ID) as SimpleMenuPlugin;
    // if (!menu) {
    //     app.getLogger()?.error("Cannot retrieve menu " + SmisApplication.MAIN_MENU_ID)
    // } else {
    //     menu.

    // }
}
