import {
    DefaultAccessorFactory,
    defaultLoggerNotifierPluginConfig,
    DivContentPlugin,
    Logger,
    LoggerNotifierPlugin,
    LoggerPlugin,
    SimpleMenuPlugin,
    SplashScreenPlugin,
    TimeManagerPlugin,
    TpzApplication,
    TpzApplicationConfig,
    TpzApplicationEvent,
    TpzApplicationEventType,
    UserLoginPlugin,
    UserLoginPluginConnectionUI,
    ServerConnectionPluginUI,
    UserMenuPluginUI,
    TpzApplicationCommander,
    StateIOPluginUI,
    defaultUserMenuPluginUIConfig,
    defaultUserLoginPluginConnectionUIConfig,
    defaultServerSyncPluginConfig,
    ServerSyncPlugin,
    ServerSyncPluginConfig,
    ServerSyncConnectionUIPluginConfig,
    defaultServerSyncConnectionUIPluginConfig,
    ServerSyncConnectionUIPlugin
} from 'topaz';

import { create3DDesktop } from './desktops/3d-desktop';
import { createBasicDesktop } from './desktops/basic-desktop';
import { createChartDesktop } from './desktops/chart-desktop';
import { createChatboxDesktop } from './desktops/chat-desktop';
import { createLabelDesktop } from './desktops/label-desktop';
import { createMapDesktop } from './desktops/map-desktop';
import { BasicMainMenuPlugin } from './plugins/menu/basic-main-menu';

/**
 * This application configuration
 */
export type SPAApplicationConfig = TpzApplicationConfig;

// Add-On Ids constants
export const AMCHARTS_ADDON_ID: string = 'amcharts-addon-id';
export const BABYLON_ADDON_ID: string = 'babylon-addon-id';
export const CESIUM_ADDON_ID: string = 'cesium-addon-id';
export const DUMMY_ADDON_ID: string = 'dummy-addon-id';
export const HELLO_WORLD_ADDON_ID: string = 'hello-world-addon-id';
export const IFRAME_ADDON_ID: string = 'iframe-addon-id';
export const OL_ADDON_ID: string = 'ol-addon-id';
// export const OPENLAYER_ADDON_ID: string = 'openlayer-addon-id';
export const SIMPLE_DIV_ADDON_ID: string = 'simple-div-creator-addon-id';
export const TABULATOR_ADDON_ID: string = 'tabulator-addon-id';
export const SPACE_CAT_ADDON_ID: string = 'space-cat-addon-id';
export const CHATBOX_ADDON_ID: string = 'chatbox-addon-id';

const ADDONS_LOCATION: { [id: string]: string } = {
    [AMCHARTS_ADDON_ID]: 'js/addons/amcharts',
    [BABYLON_ADDON_ID]: 'js/addons/babylon',
    [CESIUM_ADDON_ID]: 'js/addons/cesium',
    [DUMMY_ADDON_ID]: 'js/addons/dummy',
    [HELLO_WORLD_ADDON_ID]: 'js/addons/hello-world',
    [IFRAME_ADDON_ID]: 'js/addons/iframe',
    [OL_ADDON_ID]: 'js/addons/ol',
    // [OPENLAYER_ADDON_ID]: 'js/addons/openlayer',
    [SIMPLE_DIV_ADDON_ID]: 'js/addons/simple-div-creator',
    [TABULATOR_ADDON_ID]: 'js/addons/tabulator',
    [SPACE_CAT_ADDON_ID]: 'js/addons/space-cat',
    [CHATBOX_ADDON_ID]: 'js/addons/chatbox-addon'
};

export const HTML_VIEWER_TYPE: string = 'HTMLViewerType'; // type view defined in IFrame AddOn

/**
 * SPA Application configuration
 * This configuration is used to create the application instance
 */
const defaultApplicationConfig: SPAApplicationConfig = {
    id: 'SPA-Application',
    css: ['css/spa.css', 'css/splash.css', 'css/progress.css', 'css/jspanel-spa.css', 'css/progress-loader.css'],
    logEvents: false,
    backendURL: 'http://172.26.44.138:58407',
    applicationContainerId: 'application-container',
    addOnRegistryURLs: [],
    addOnIds: [],
    configFileURL: 'topaz-config.json'
};

/**
 * Desktop identifier with id and name
 */
export interface DesktopIdentifier {
    id: string;
    name: string;
}

export class SPAApplication extends TpzApplication {
    public static readonly MAIN_MENU_ID: string = 'main-menu';
    //public static readonly APPLICATION_CONTAINER_ID: string = "application-container";
    public static readonly TOOLBAR_ID: string = 'spa-toolbar';
    public static readonly HEADER_ID: string = 'spa-header';
    public static readonly LOGO_ID: string = 'spa-logo';
    public static readonly LOGGER_ID: string = 'spa-logger';
    public static readonly LOGGER_NOTIFICATION_ID: string = 'spa-logger-notification';
    public static readonly FOOTER_ID: string = 'spa-footer';

    public static readonly HOME_DESKTOP_ID: DesktopIdentifier = {
        id: 'home-desktop',
        name: 'Home'
    };
    public static readonly FRAME_DESKTOP_ID: DesktopIdentifier = {
        id: 'frame-desktop',
        name: 'Frame Samples'
    };
    public static readonly THREED_DESKTOP_ID: DesktopIdentifier = {
        id: 'threeD-desktop',
        name: '3D'
    };
    public static readonly TABULATOR_DESKTOP_ID: DesktopIdentifier = {
        id: 'tabulator-desktop',
        name: 'Tabulator samples'
    };
    public static readonly CHART_DESKTOP_ID: DesktopIdentifier = {
        id: 'chart-desktop',
        name: 'Charts Samples'
    };
    public static readonly CARTO_DESKTOP_ID: DesktopIdentifier = {
        id: 'carto-desktop',
        name: 'Cartograpy'
    };
    public static readonly SYNOPTICS_DESKTOP_ID: DesktopIdentifier = {
        id: 'synoptics-desktop',
        name: 'Synotics'
    };
    public static readonly CHATBOX_DESKTOP_ID: DesktopIdentifier = {
        id: 'chatbox-desktop',
        name: 'ChatBox'
    };

    private timeManager: TimeManagerPlugin = null;
    private userLoginPlugin: UserLoginPlugin = null;
    private userLoginPluginConnectionUI: UserLoginPluginConnectionUI = null;
    private menuPlugin: SimpleMenuPlugin = null;
    private userMenuPluginUI: UserMenuPluginUI = null;
    // public static readonly RSO_CATALOG_ACCESSOR_ID: string = "main-rso-catalog-accessor-id";
    public static readonly SYNC_PLUGIN_ID: string = 'sync-plugin-id';
    public static readonly SYNC_UI_PLUGIN_ID: string = 'sync-ui-plugin-id';
    public static readonly RSO_PLUGIN_ID: string = 'main-rso-catalog-plugin-id';
    public static readonly SATCAT_ACCESSOR_ID: string = 'satcat-accessor-id';

    /**
     * Basic application constructor
     * @param config Basic application configuration
     */
    constructor(config: SPAApplicationConfig = {}) {
        super({ ...defaultApplicationConfig, ...config });
        this.addSplashScreen();
        this.addHeader();
        this.addLogo();
        this.addMainMenu();
        this.addToolbar();
        // this.addMainContent();
        this.addFooter();
        this.addStateIO();
        this.addLoggerUI();
        this.addServerConnectionUI();
        // this.addLoggerNotifier();
        this.addUserLogin();
        this.addUserMenuUI();
        this.addTimeManager();
        // this.addRSOCatalog();
        this.addSync();

        // default SMIS items
        this.addDefaultItems();
        // main menu initialization use application desktop manager to fill desktop menus
        this.updateMenu();

        // register all local addons
        Object.keys(ADDONS_LOCATION).forEach((addOnId: string) => {
            this.getAddOnManager().registerLocalAddOn(addOnId, ADDONS_LOCATION[addOnId]);
        });

        // // automatically load some add-ons
        // this.getAddOnManager().loadAddOnById(IFRAME_ADDON_ID);
        // this.getAddOnManager().loadAddOnById(CESIUM_ADDON_ID);
        // this.getAddOnManager().loadAddOnById(SPACE_CAT_ADDON_ID);

        // desktops
        TpzApplicationCommander.registerItem(this, createBasicDesktop(this));
        TpzApplicationCommander.registerItem(this, create3DDesktop(this));
        TpzApplicationCommander.registerItem(this, createLabelDesktop(this));
        TpzApplicationCommander.registerItem(this, createChartDesktop(this));
        TpzApplicationCommander.registerItem(this, createMapDesktop(this));
        TpzApplicationCommander.registerItem(this, createChatboxDesktop(this));
        // TpzApplicationCommander.registerItem(this, createSynopticsDesktop(this));
    }

    /**
     * Add default items in items catalog
     */
    private addDefaultItems(): void {
        // JsonRemoteAccessor is defined in DefaultAccessorFactory factory
        this.getFactoryManager().registerItemFactory(new DefaultAccessorFactory(this));
        // SatCatRSOAccessor is defined in RSOAccessorFactory

        // let rsos: RSO[] = [];
        // let nbItems: number = 1000;
        // for (let n = 0; n < nbItems; n++) {
        //     rsos.push({
        //         id: "RSO#" + n,
        //         type: "RSO",
        //         shortName: TpzApplicationHelper.uuidv4(),
        //         name: "RSO number " + n,
        //     });
        // }
        // let rsoCatalogAccessorConfig: StaticValueAccessorConfig<RSO[]> = {
        //     id: SmisApplication.RSO_CATALOG_ACCESSOR_ID,
        //     type: StaticValueAccessor.STATIC_ACCESSOR_TYPE,
        //     value: rsos,
        // }
        // this.getFactoryManager().registerItemFactory(new DefaultAccessorFactory(new CatalogItemRetriever(this.getItemCatalog())));
        // this.getItemCatalog().registerInstanceConfig(rsoCatalogAccessorConfig);

        // This catalog is inserted in RSOAccessor list as source of RSOFilters
        // let rsoCatalogAccessorConfig: JsonRemoteAccessorConfig = {
        //     ...defaultJsonRemoteAccessorConfig,
        //     id: SmisApplication.RSO_CATALOG_ACCESSOR_ID,
        //     // type: JsonRemoteAccessor.JSON_REMOTE_ACCESSOR_TYPE,
        //     method: "GET",
        //     url: "http://localhost:6010/file:../../smis-frontend/SMIS/src/main/site/data/catalog/celestrak.json",
        //     // specialize Json remote accessor to RSO accessor
        //     categories: [RSOAccessor.RSO_ACCESSOR_ITEM_CATEGORY].concat(defaultJsonRemoteAccessorConfig.categories),
        // }
        // this.getItemCatalog().registerInstanceConfig(rsoCatalogAccessorConfig);
    }

    /**
     * Event callbacks
            // when the desktop changes, update the menu plugin
            case TpzApplicationEventType.DESKTOP_ACTIVATED:
                TpzApplicationEventType.checkEvent(event, 'activatedDesktopId', 'deactivatedDesktopId');
                this.updateMenu();
                return true;
            // // when time change, send it to the server
            // case TimePickerEventCatalog.TIME_CHANGED:
            //     let timeChangedContent: ToServerTimeChangedMessage = {
            //         time: event.content.time
            //     }
            //     this.sendMessage(ToServerBasicMessageProtocol.TIME_CHANGED, timeChangedContent);
            //     return true;
        }
        return super.onApplicationEvent(event);
    }

    /**
     * Logger window containing log messages and notification icon
     */
    private addLoggerUI(): void {
        const me: SPAApplication = this;
        // top menu
        this.getPluginManager().addPlugin(
            new LoggerPlugin({
                id: 'basic-logger',
                pluginDependencies: [SPAApplication.TOOLBAR_ID],
                parentId: SPAApplication.TOOLBAR_ID,
                css: ['css/logger.css'],
                icon: 'images/icons/log.png'
            })
        );
    }

    /**
     * Server connection UI displays a logo and edit the application configuration
     * about server URL
     */
    private addServerConnectionUI(): void {
        const me: SPAApplication = this;
        // top menu
        this.getPluginManager().addPlugin(
            new ServerConnectionPluginUI({
                id: 'server-connection-ui',
                pluginDependencies: [SPAApplication.TOOLBAR_ID],
                parentId: SPAApplication.TOOLBAR_ID,
                css: ['css/server-connection-ui.css']
            })
        );
    }

    /**
     * Logger window containing notifications
     */
    private addLoggerNotifier(): void {
        const me: SPAApplication = this;
        // top menu
        this.getPluginManager().addPlugin(
            new LoggerNotifierPlugin({
                id: 'notifier-logger',
                parentId: this.getConfig().applicationContainerId,
                css: ['css/logger-notifier.css'],
                divClasses: ['logger-notification'],
                notificationLevels: {
                    ...defaultLoggerNotifierPluginConfig.notificationLevels,
                    ...{
                        [Logger.DEBUG]: {
                            display: false,
                            autoCloseDelay: 0
                        }
                    }
                }
            })
        );
    }

    /**
     * Add Time Manager Plugin
     */
    private addTimeManager(): void {
        const me: SPAApplication = this;
        this.getPluginManager().addPlugin(this.getTimeManagerPlugin());
    }

    /** Time manager plugin lazy getter */
    public getTimeManagerPlugin(): TimeManagerPlugin {
        if (!this.timeManager) {
            this.timeManager = new TimeManagerPlugin({
                id: 'basic-time-manager'
            });
        }
        return this.timeManager;
    }
    // /**
    //  * Add RSO Catalog Plugin
    //  */
    // private addRSOCatalog(): void {
    //   let me: SPAApplication = this;
    //   let catalogFilter: RSOFilter = RSOFilterHelper.createRSOFilter(
    //     "catalog",
    //     RSOFilterTypes.IDENTITY,
    //     SPAApplication.SATCAT_ACCESSOR_ID,
    //     "full-catalog"
    //   );
    //   let exampleFilter: RSOFilter = RSOFilterHelper.createRSOFilter(
    //     "filter-test",
    //     RSOFilterTypes.ID_SET,
    //     SPAApplication.SATCAT_ACCESSOR_ID,
    //     "filter-test-accessor"
    //   );
    //   RSOFilterIdSetHelper.setRSOFilterIdSetParameter(exampleFilter, {
    //     "THOR ABLESTAR DEB": true,
    //   });
    //   // store plugin
    //   this.rsoCatalogPlugin = new RSOCatalogPlugin({
    //     id: SPAApplication.RSO_PLUGIN_ID,
    //     outputRSOFilters: [],
    //   });
    //   this.rsoCatalogPlugin.addOrUpdateRSOFilter(catalogFilter);
    //   this.rsoCatalogPlugin.addOrUpdateRSOFilter(exampleFilter);
    //   this.getPluginManager().addPlugin(this.rsoCatalogPlugin);
    // }

    // /**
    //  * Catalog Plugin getter
    //  */
    // public getRSOCatalogPlugin(): RSOCatalogPlugin {
    //   return this.rsoCatalogPlugin;
    // }

    /**
     * Sync plugin managing connection with server (launches two plugins: sync core and sync UI)
     */
    private addSync(): void {
        // add connection server plugin
        const pluginConfig: ServerSyncPluginConfig = {
            ...defaultServerSyncPluginConfig,
            id: SPAApplication.SYNC_PLUGIN_ID
        };
        this.getPluginManager().addPlugin(new ServerSyncPlugin(pluginConfig));

        // add UI connection server plugin
        const pluginUIConfig: ServerSyncConnectionUIPluginConfig = {
            ...defaultServerSyncConnectionUIPluginConfig,
            id: SPAApplication.SYNC_UI_PLUGIN_ID,
            // css: ["css/sync.css"],
            parentId: SPAApplication.TOOLBAR_ID,
            pluginDependencies: [SPAApplication.TOOLBAR_ID]
            // syncIcon: 'images/icons/sync.png'
        };
        this.getPluginManager().addPlugin(new ServerSyncConnectionUIPlugin(pluginUIConfig));
    }

    /**
     * User plugin managing connection to users
     */
    private addUserLogin(): void {
        const self = this;
        this.userLoginPlugin = new UserLoginPlugin({
            id: 'tpz-user-login',
            logMessages: true,
            css: ['css/userLogin.css']
        });
        this.getPluginManager().addPlugin(this.userLoginPlugin);

        this.userLoginPluginConnectionUI = new UserLoginPluginConnectionUI({
            ...defaultUserLoginPluginConnectionUIConfig,
            id: 'tpz-user-login-connection-ui',
            pluginDependencies: [SPAApplication.TOOLBAR_ID, this.userLoginPlugin.getConfig().id],
            logoutIcon: 'images/icons/user.png',
            loginIcon: 'images/icons/user-authenticated.png',
            parentId: SPAApplication.TOOLBAR_ID,
            secureMode: true
        });
        this.getPluginManager().addPlugin(this.userLoginPluginConnectionUI);
    }

    /**
     * User plugin displaying user menu for connected users
     */
    private addUserMenuUI(): void {
        this.userMenuPluginUI = new UserMenuPluginUI({
            ...defaultUserMenuPluginUIConfig,
            id: 'tpz-user-menu-ui',
            pluginDependencies: [SPAApplication.MAIN_MENU_ID, this.userLoginPlugin.getConfig().id],
            menuPluginId: SPAApplication.MAIN_MENU_ID
            // secureMode: true
        });
        this.getPluginManager().addPlugin(this.userMenuPluginUI);
    }

    // main menu
    private addMainMenu(): void {
        if (!this.menuPlugin) {
            this.menuPlugin = new BasicMainMenuPlugin();
            this.getPluginManager().addPlugin(this.menuPlugin);
            return;
        }
    }

    /**
     * Update the menu
     */
    private updateMenu(): void {
        if (this.menuPlugin) this.menuPlugin.update();
    }

    // Splashscreen
    private addSplashScreen(): void {
        this.getPluginManager().addPlugin(
            new SplashScreenPlugin({
                id: 'splash',
                textElements: ['Neo-Applications', 'Powered By Topaz', 'CSGroup® 2020'],
                duration: 8000,
                closeOnClick: true,
                videoURL: 'images/background/Smoke_49_-_4K_res.mp4',
                // videoURL: "https://www.youtube.com/watch?v=9N2_P6NK2A4&list=PLjo25GEVu51sNzzAoNa09H-BDM5sPlZmD",
                videoLoop: false
            }),
            false
        );
    }

    // toolbar content
    private addToolbar(): void {
        this.getPluginManager().addPlugin(
            new DivContentPlugin({
                id: SPAApplication.TOOLBAR_ID,
                divId: SPAApplication.TOOLBAR_ID,
                parentId: SPAApplication.HEADER_ID,
                pluginDependencies: [SPAApplication.HEADER_ID],
                divClasses: ['smis-toolbar'],
                css: ['css/toolbar.css']
            })
        );
    }

    // Logo content
    private addLogo(): void {
        this.getPluginManager().addPlugin(
            new DivContentPlugin({
                id: SPAApplication.LOGO_ID,
                divId: SPAApplication.LOGO_ID,
                parentId: SPAApplication.HEADER_ID,
                pluginDependencies: [SPAApplication.HEADER_ID],
                divClasses: ['smis-logo'],
                css: ['css/logo.css']
            })
        );
    }

    // header content
    private addHeader(): void {
        this.getPluginManager().addPlugin(
            new DivContentPlugin({
                id: SPAApplication.HEADER_ID,
                divId: SPAApplication.HEADER_ID,
                divClasses: ['smis-header']
            })
        );
    }

    // footer content
    private addFooter(): void {
        this.getPluginManager().addPlugin(
            new DivContentPlugin({
                id: SPAApplication.FOOTER_ID,
                divId: SPAApplication.FOOTER_ID,
                divClasses: ['smis-footer'],
                content: '<span>Powered by ToPaZ ®CSGroup</span>'
            })
        );
    }

    /**
     * State IO UI displays an icon allowing to copy current application state or load an application state with drop
     */
    private addStateIO(): void {
        const me: SPAApplication = this;
        // top menu
        this.getPluginManager().addPlugin(
            new StateIOPluginUI({
                id: 'state-io-ui',
                pluginDependencies: [SPAApplication.TOOLBAR_ID],
                parentId: SPAApplication.TOOLBAR_ID,
                css: ['css/state-io-ui.css']
            })
        );
    }

    /**
     * Event callback
     * @param event event received
     * @returns if event is treated or not
     */
    protected onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            case TpzApplicationEventType.APPLICATION_STARTED:
                {
                    //     // stop playing at start
                    //     let timeDescriptor: SimulatedTimeDescriptor = this.getTimeManagerPlugin().getTimeDescriptor();
                    //     timeDescriptor.playing = false;
                    //     this.getTimeManagerPlugin().setTimeDescriptor(timeDescriptor);
                    //     // open carto desktop
                    //     TpzApplicationCommander.openSingleDesktop(this, SPAApplication.CARTO_DESKTOP_ID.id);
                }
                break;
        }
        return super.onApplicationEvent(event);
    }
}
