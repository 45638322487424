import { HTMLViewer, HTMLViewerConfig, HTMLViewerFactory } from '@tpz-addon/iframe-addon';
import { SPAApplication } from '../../spa-client';
import {
    TpzApplicationCommander,
    TpzDesktop,
    TpzWindow,
    TpzWindowConfig,
    Logger,
    SimpleMenuHelper,
    SimpleMenuPlugin,
    TpzAddOn,
    TpzAddOnHelper,
    TpzApplication,
    TpzApplicationEvent,
    TpzApplicationEventType,
    TpzMenuElement,
    uuidv4,
    NewTpzViewConfig,
    NewTpzViewFactory,
    NewTpzView,
    TpzApplicationTypes
} from 'topaz';

export class BasicMainMenuPlugin extends SimpleMenuPlugin {
    constructor() {
        super({
            id: SPAApplication.MAIN_MENU_ID,
            reloadOnDesktopChange: true,
            css: ['css/spa-main-menu.css'],
            pluginDependencies: [SPAApplication.HEADER_ID, SPAApplication.LOGO_ID],
            menuContainerClass: 'nav-container',
            parentId: SPAApplication.HEADER_ID
        });
    }

    /**
     * Fill Menu Elements (config.rootElements)
     * This config is used to create the menu when calling update() method
     */
    private fillElements(): void {
        const me: BasicMainMenuPlugin = this;

        const desktopsMenuItem: TpzMenuElement = me.createDesktopsMenuItems();
        SimpleMenuHelper.addOrReplaceMenuElementToArray(desktopsMenuItem, this.getRootElements());
        SimpleMenuHelper.addOrReplaceMenuElementToArray(me.createItemFactoriesMenuItems(), this.getRootElements());
        SimpleMenuHelper.addOrReplaceMenuElementToArray(me.createDesktopControlMenuItems(), this.getRootElements());
        SimpleMenuHelper.addOrReplaceMenuElementToArray(me.createOptionsMenuItems(), this.getRootElements());
        SimpleMenuHelper.addOrReplaceMenuElementToArray(me.createAddOnsMenuItems(), this.getRootElements());
    }

    /**
     * change the content of the menu 'Options'
     */
    private createOptionsMenuItems(): TpzMenuElement {
        const app: TpzApplication = this.getApplication();
        const subElements: TpzMenuElement[] = [];

        const currentDesktop: TpzDesktop = app?.getDesktopManager().getActiveDesktop();
        if (currentDesktop) {
            subElements.push({
                id: 'preferences',
                name: 'Preferences',
                i18n: 'main-menu-preferences',
                action: () => {
                    if (!app) return null;
                    app.registerItemFactory(new HTMLViewerFactory(app));
                    const viewPreferencesConfig: HTMLViewerConfig = {
                        id: uuidv4(), // override ID
                        type: HTMLViewer.HTML_VIEWER_TYPE,
                        htmlContent: 'no content'
                    };
                    TpzApplicationCommander.displayItemNewWindowInActiveDesktopByConfig(
                        app,
                        viewPreferencesConfig,
                        true,
                        `view-preferences-window-${uuidv4()}`
                    );

                    // lsdkflmskdf
                    // TpzApplicationUI.showModal(app, {

                    //     mainId: "modalId",
                    //     content: "<h1>Desktop " + currentDesktop.getName() + " #" + currentDesktop.getId() + "</h1><pre>" +
                    //         JSON.stringify(currentDesktop.exportConfig(), null, 2) + "</pre>"
                    // });
                }
            });
        }

        return {
            id: 'options',
            name: 'Options',
            i18n: 'main-menu-option',
            html: "<img target='_blank' src='https://d1guu6n8gz71j.cloudfront.net/system/asset/logos/262092/logo.png?1568190183' alt='CS Group'>",
            subElements: subElements
        };
    }

    /**
     * change the content of the menu 'AddOns'
     */
    private createAddOnsMenuItems(): TpzMenuElement {
        const app: TpzApplication = this.getApplication();

        return {
            id: 'addons',
            name: 'AddOns',
            i18n: 'main-menu-addons',
            subElements: [
                {
                    id: 'addons-dummy',
                    name: 'Dummy',
                    i18n: 'main-menu-addon-dummy',
                    action: () => {
                        Logger.info('Load Dummy Add-On');
                        TpzAddOnHelper.loadTpzAddOnFromURL('./js/ext/dummy/tpz-addon.json')
                            .then((ext: TpzAddOn) => {
                                ext.start(app);
                            })
                            .catch((reason: any) => {
                                Logger.error(`Error loading Extension: ${reason}`);
                            });
                    }
                },
                {
                    id: 'addons-select',
                    name: 'select',
                    //                        enabled: true,
                    action: () => {
                        Logger.info('Load Dummy Add-On');
                        // alert("SimpleMenuHelper.displayItemParametersModule has been deprecated");
                        SimpleMenuHelper.displaySelectAddOnView(app);
                    }
                }
            ]
        };
    }

    /**
     * change the content of the menu 'Elements' with all registered Items
     */
    private createDesktopControlMenuItems(): TpzMenuElement {
        return {
            id: 'desktop-control',
            name: 'Desktop Control',
            i18n: 'main-menu-file',
            iconSrc: 'images/icons/icons8-yellow-file-64.png',
            subElements: SimpleMenuHelper.createCurrentDesktopRegisteredItemsCommands(this.getApplication())
        };
    }

    /**
     * change the content of the menu 'Desktops' with all registered application desktops
     */
    private createDesktopsMenuItems(): TpzMenuElement {
        const me = this;
        // Construct the Desktops elements (with 'new' & 'close all')
        let elements: TpzMenuElement[] = [];
        // 'new desktop' Menu Item
        elements.push({
            id: 'desktop-new',
            name: 'New',
            i18n: 'main-menu-new-desktop',
            action: () => {
                let newDesktopItemConfig: NewTpzViewConfig = {
                    id: `new-desktop-view-${uuidv4()}`,
                    type: NewTpzView.NEW_DESKTOP_VIEW_TYPE,
                    desktopId: uuidv4()
                };
                this.getApplication()
                    .getItemCatalog()
                    .getFactoryManager()
                    .registerItemFactory(new NewTpzViewFactory(this.getApplication()));
                TpzApplicationCommander.displayItemNewWindowInActiveDesktopByConfig(
                    this.getApplication(),
                    newDesktopItemConfig,
                    false,
                    `window-${newDesktopItemConfig.id}`
                );
            }
        });
        // All existing desktops
        elements = elements.concat(SimpleMenuHelper.createRegisteredDesktopMenuItems(me.getApplication()));
        // 'Close All' Menu Element
        elements.push({
            id: 'desktop-close-all',
            name: 'close all',
            action: () => TpzApplicationCommander.closeAllDesktops(this.getApplication())
        });
        // return the Desktops Menu Element
        return {
            id: 'desktop-main',
            name: 'Desktops',
            i18n: 'main-menu-desktops',
            subElements: elements
        };
    }

    /**
     * change the content of the menu 'Item Factories' with all application factories
     */
    private createItemFactoriesMenuItems(): TpzMenuElement {
        return {
            id: 'items',
            name: 'Items',
            i18n: 'main-menu-items',
            subElements: SimpleMenuHelper.createRegisteredFactoriesMenuItem(this.getApplication(), null)
        };
        // this.update();
    }

    /**
     * Overload update: it fills the config.rootElement variable using the application variable (this.getApplication())
     * then it calls super.update() which uses config.rootElement to create and replace the current menu
     */
    public update(): Promise<void> {
        return Promise.resolve()
            .then(() => this.fillElements())
            .catch((reason: any) =>
                this.getLogger().error('An error occurred filling basic main menu elements', reason)
            )
            .then(() => super.update());
    }

    /**
     * Action to perform on application events
     */
    public onApplicationEvent(event: TpzApplicationEvent): boolean {
        switch (event.type) {
            // update menu if a desktop is registered
            case TpzApplicationEventType.ITEM_CONFIG_REGISTERED:
                TpzApplicationEventType.checkEvent(event, 'itemConfig');
                if (event.content.itemConfig?.type == TpzApplicationTypes.TPZ_DESKTOP_TYPE) this.update();
                break;
            // update menu if a desktop is unregistered
            case TpzApplicationEventType.ITEM_CONFIG_UNREGISTERED:
                TpzApplicationEventType.checkEvent(event, 'itemConfig');
                if (event.content.itemConfig?.type == TpzApplicationTypes.TPZ_DESKTOP_TYPE) this.update();
                break;
            // update menu if a desktop is activated
            case TpzApplicationEventType.DESKTOP_ACTIVATED:
                TpzApplicationEventType.checkEvent(event, 'activatedDesktopId', 'deactivatedDesktopId');
                this.update();
                break;
            case TpzApplicationEventType.ITEM_FACTORY_REGISTERED:
                TpzApplicationEventType.checkEvent(event, 'factory');
                this.update();
                break;
            case TpzApplicationEventType.ITEM_FACTORY_UNREGISTERED:
                TpzApplicationEventType.checkEvent(event, 'factoryId');
                this.update();
                break;
            case TpzApplicationEventType.ITEM_STATE_CHANGED:
                TpzApplicationEventType.checkEvent(event, 'itemId', 'oldState', 'newState');
                this.update();
                break;
        }
        return super.onApplicationEvent(event);
    }
}
