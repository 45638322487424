import {
    TpzApplication,
    TpzDesktopConfig,
    TpzWindow,
    TpzWindowConfig,
    TpzWindowFactory,
    ClockFactory,
    ClockViewDigitalConfig,
    ClockViewDigital,
    ClockViewAnalogConfig,
    ClockViewAnalog,
    TimePickerView,
    TimePickerViewConfig,
    TimePickerViewFactory,
    TpzApplicationCommander,
    TpzDesktop,
    TpzApplicationTypes
} from 'topaz';
import { SPAApplication } from '../spa-client';

// import { RSOCatalogDisplayer, RSOCatalogDisplayerConfig } from "../displayer/rso/rso-list-displayer";
// import { RSODisplayerFactory } from "../displayer/rso/rso-list-factory";

export function createBasicDesktop(app: TpzApplication): TpzDesktopConfig {
    app.getFactoryManager().registerItemFactory(new TpzWindowFactory(app));
    app.getFactoryManager().registerItemFactory(new ClockFactory(app));
    app.getFactoryManager().registerItemFactory(new TimePickerViewFactory(app));

    // app.getFactoryManager().registerItemFactory(new RSODisplayerFactory(app));

    // Paris Clock process
    const parisClockViewDigitalConfig: ClockViewDigitalConfig = {
        id: 'paris-clock-view-digital',
        type: ClockViewDigital.CLOCK_VIEW_DIGITAL_TYPE,
        location: 'Paris',
        timezone: +1,
        childrenIds: []
    };
    TpzApplicationCommander.registerItem(app, parisClockViewDigitalConfig);

    // Paris Clock process
    const parisClockViewAnalogConfig: ClockViewAnalogConfig = {
        id: 'paris-clock-view-analog',
        type: ClockViewAnalog.CLOCK_VIEW_ANALOG_TYPE,
        location: 'Paris',
        timezone: +1,
        childrenIds: []
    };
    TpzApplicationCommander.registerItem(app, parisClockViewAnalogConfig);

    // Window used to display clocks
    const parisClockWindowConfig: TpzWindowConfig = {
        id: 'clock-window',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        //        displayerIds: [parisClockDisplayerConfig.id, moscowClockDisplayerConfig.id, beijingClockDisplayerConfig.id],

        jsPanelOptions: {
            position: 'left-top 4px 5px',
            panelSize: '140px 190px',
            headerControls: 'closeonly xs'
        },
        title: 'Paris',
        childrenIds: [parisClockViewDigitalConfig.id, parisClockViewAnalogConfig.id]
    };
    TpzApplicationCommander.registerItem(app, parisClockWindowConfig);

    // Moscow Clocks view
    const moscowViewDigitalConfig: ClockViewDigitalConfig = {
        id: 'moscow-clock-view-digital',
        type: ClockViewDigital.CLOCK_VIEW_DIGITAL_TYPE,
        location: 'Moscow',
        timezone: +3,
        childrenIds: []
    };
    TpzApplicationCommander.registerItem(app, moscowViewDigitalConfig);

    // Beijing Clocks view
    const beijingViewDigitalConfig: ClockViewDigitalConfig = {
        id: 'beijing-clock-view-digital',
        type: ClockViewDigital.CLOCK_VIEW_DIGITAL_TYPE,
        location: 'Beijing',
        timezone: +8,
        childrenIds: []
    };
    TpzApplicationCommander.registerItem(app, beijingViewDigitalConfig);

    // Window used to display clocks
    const worldClocksWindowConfig: TpzWindowConfig = {
        id: 'world-clocks-window',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        //        displayerIds: [parisClockDisplayerConfig.id, moscowClockDisplayerConfig.id, beijingClockDisplayerConfig.id],

        jsPanelOptions: {
            position: 'left-top 145px 5px',
            panelSize: '140px 120px',
            headerControls: 'closeonly xs'
        },
        title: 'World Clocks',
        childrenIds: [moscowViewDigitalConfig.id, beijingViewDigitalConfig.id]
    };
    TpzApplicationCommander.registerItem(app, worldClocksWindowConfig);

    // TimePicker
    let timePickerViewConfig: TimePickerViewConfig = {
        id: 'time-displayer2',
        type: TimePickerView.TIMEPICKER_VIEW_TYPE,
        css: ['css/time-picker.css', 'css/roller.css', 'css/knob.css']
    };
    TpzApplicationCommander.registerItem(app, timePickerViewConfig);

    let timePickerWindowConfig: TpzWindowConfig = {
        id: 'time-picker-window2',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [timePickerViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 420.621px 706.545px',
            panelSize: '680.895px 140px'
        },
        title: 'Time Picker'
    };
    TpzApplicationCommander.registerItem(app, timePickerWindowConfig);

    // Complete desktop
    const desktopConfig: TpzDesktopConfig = {
        id: SPAApplication.HOME_DESKTOP_ID.id,
        type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
        name: SPAApplication.HOME_DESKTOP_ID.name,
        childrenIds: [parisClockWindowConfig.id, worldClocksWindowConfig.id, timePickerWindowConfig.id]
    };
    return desktopConfig;

    // let menu: SimpleMenuPlugin = app.getPluginManager().getPluginById(SmisApplication.MAIN_MENU_ID) as SimpleMenuPlugin;
    // if (!menu) {
    //     app.getLogger()?.error("Cannot retrieve menu " + SmisApplication.MAIN_MENU_ID)
    // } else {
    //     menu.

    // }
}

// export function createMapDesktop(app: TpzApplication): TpzDesktopConfig {
//     app.getFactoryManager().registerItemFactory(new TpzModuleFactory(app));
//     app.getFactoryManager().registerItemFactory(new OpenLayersDisplayerFactory(app));

//     // Map displayer
//     let mapDisplayer1Config: OpenLayersDisplayerConfig = {
//         id: "map-displayer",
//         type: OpenLayersDisplayer.OPENLAYERS_DISPLAYER_TYPE
//     };

//     // Module for Displayer
//     let moduleDisplayerConfig: TpzModuleDisplayerConfig = {
//         id: "map-module",
//         type: TpzModuleDisplayer.MODULE_DISPLAYER_TYPE,
//         displayerIds: [mapDisplayer1Config.id],
//         jsPanelOptions: {
//             position: "left-top 10px 10px",
//             panelSize: "719.091px 544.545px",
//             setStatus: "maximized",
//             dragit: {
//                 snap: true,
//             },
//         },
//         title: "Map"
//     };

//     // Complete desktop
//     let desktopConfig: TpzDesktopConfig = {
//         id: SmisApplication.CARTO_DESKTOP_ID,
//         name: SmisApplication.CARTO_DESKTOP_ID,
//         itemConfigs: [
//             mapDisplayer1Config,
//             moduleDisplayerConfig,
//         ]
//     };
//     return desktopConfig;

// }
