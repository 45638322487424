import { TpzWindowFactory } from 'topaz';
import { TpzApplication } from 'topaz';
import { TpzDesktopConfig } from 'topaz';
import { TpzApplicationCommander } from 'topaz';
import { ItemConfig } from 'topaz';
import { TpzWindowConfig } from 'topaz';
import { TpzApplicationTypes } from 'topaz';
import { SPAApplication } from '../spa-client';

export const CHATBOX_ADDON_ID: string = 'chatbox-addon-id';

export function createChatboxDesktop(app: TpzApplication): TpzDesktopConfig {
    app.getFactoryManager().registerItemFactory(new TpzWindowFactory(app));
    app.getAddOnManager().registerLocalAddOn(CHATBOX_ADDON_ID, './js/addons/chatbox-addon/');

    // Chatbox controller config
    const chatboxControllerConfig: ItemConfig = {
        id: 'chatbox-controller',
        type: 'ChatboxControllerType',
        addOnIds: [CHATBOX_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, chatboxControllerConfig);

    // Chatbox view config
    const chatboxViewConfig: ItemConfig = {
        id: 'chatbox-displayer',
        type: 'ChatboxChannelListView',
        childrenIds: [chatboxControllerConfig.id],
        addOnIds: [CHATBOX_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, chatboxViewConfig);

    // Chatbox window config
    const chatboxWindowConfig: TpzWindowConfig = {
        id: 'chatbox-window',
        type: 'TpzWindowType',
        childrenIds: [chatboxViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 0px 0px',
            panelSize: '400px 200px',
            syncMargins: true,
            setStatus: 'maximized'
        },
        title: 'ChatBox ToPaZ'
    };
    TpzApplicationCommander.registerItem(app, chatboxWindowConfig);

    // Chatbox Desktop
    const chatboxDesktopConfig: TpzDesktopConfig = {
        id: SPAApplication.CHATBOX_DESKTOP_ID.id,
        name: SPAApplication.CHATBOX_DESKTOP_ID.name,
        type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
        childrenIds: [chatboxWindowConfig.id]
    };
    return chatboxDesktopConfig;
}
