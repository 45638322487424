import {
    AccessData,
    DefaultAccessorFactory,
    EvolutiveTestNumberAccessor,
    // EvolutiveTestNumberAccessorConfig,
    TestAccessorFactory,
    TpzApplication,
    TpzDesktopConfig,
    TpzWindowConfig,
    TpzWindow,
    TpzWindowFactory,
    LabeledViewFactory,
    LabeledViewConfig,
    LabeledView,
    TpzApplicationCommander,
    TpzDesktop,
    AccessorType,
    TpzApplicationTypes
} from 'topaz';

import { HTML_VIEWER_TYPE, IFRAME_ADDON_ID, SPAApplication } from '../spa-client';

export function createLabelDesktop(app: TpzApplication): TpzDesktopConfig {
    app.getFactoryManager().registerItemFactory(new TpzWindowFactory(app));
    app.getFactoryManager().registerItemFactory(new LabeledViewFactory(app));
    app.getFactoryManager().registerItemFactory(new DefaultAccessorFactory(app));
    app.getFactoryManager().registerItemFactory(new TestAccessorFactory(app));

    app.getFactoryManager().registerItemFactory(new TpzWindowFactory(app));

    // const numberAccessor1Config: EvolutiveTestNumberAccessorConfig = {
    //     id: "number-accessor-1",
    //     type: AccessorType.NUMBER_EVOLUTIVE_TEST_ACCESSOR_TYPE,
    //     dataType: AccessData.NUMBER_DATATYPE,
    //     value: 5,
    //     delai: 100,
    //     increment: 2,
    //     jitterFactor: 0.2,
    //     minValue: 0,
    //     maxValue: 100
    // };
    // TpzApplicationCommander.registerItem(app, numberAccessor1Config);

    // const numberAccessor2Config: EvolutiveTestNumberAccessorConfig = {
    //     id: "number-accessor-2",
    //     type: AccessorType.NUMBER_EVOLUTIVE_TEST_ACCESSOR_TYPE,
    //     dataType: AccessData.NUMBER_DATATYPE,
    //     value: 5,
    //     delai: 100,
    //     increment: 2,
    //     jitterFactor: 0.2,
    //     minValue: 0,
    //     maxValue: 100
    // };
    // TpzApplicationCommander.registerItem(app, numberAccessor2Config);

    // // Label displayer
    // const label1ViewConfig: LabeledViewConfig = {
    //     id: "label-displayer1",
    //     label: "value 2",
    //     displayLabel: true,
    //     type: LabeledView.LABELED_VIEW_TYPE,
    //     childrenIds: [numberAccessor1Config.id]
    // };
    // TpzApplicationCommander.registerItem(app, label1ViewConfig);

    // // Label displayer
    // const label2ViewConfig: LabeledViewConfig = {
    //     id: "label-displayer2",
    //     type: LabeledView.LABELED_VIEW_TYPE,
    //     childrenIds: [numberAccessor2Config.id]
    // };
    // TpzApplicationCommander.registerItem(app, label2ViewConfig);

    // // Window used to display HTML viewer
    // const labelWindowConfig: TpzWindowConfig = {
    //     id: "label-window",
    //     type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
    //     jsPanelOptions: {
    //         position: "left-top 520px 520px",
    //         panelSize: "250px 150px"
    //     },
    //     title: "Labels",
    //     childrenIds: [label1ViewConfig.id, label2ViewConfig.id]
    // };
    // TpzApplicationCommander.registerItem(app, labelWindowConfig);

    // HTML displayer
    const htmlViewConfig1: any = {
        id: 'html-viewer-1',
        type: HTML_VIEWER_TYPE,
        iframe: true,
        //            htmlContent: (div: HTMLDivElement) => { div.innerHTML = "<h1>Hello World</h1>" },
        htmlURL: 'https://grafana.wikimedia.org/?orgId=1',
        addOnIds: [IFRAME_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, htmlViewConfig1);

    //
    // Window used to display HTML viewer
    const htmlWindowConfig1: TpzWindowConfig = {
        id: 'html-window-1',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        jsPanelOptions: {
            position: 'left-top 10px 10px',
            panelSize: '500px 500px'
        },
        title: 'Grafana',
        childrenIds: [htmlViewConfig1.id]
    };
    TpzApplicationCommander.registerItem(app, htmlWindowConfig1);

    // HTML displayer
    const htmlViewConfig2: any = {
        id: 'html-viewer-2',
        type: HTML_VIEWER_TYPE,
        iframe: true,
        htmlURL: 'https://www.youtube.com/embed/vHWDNrrfhnI',
        addOnIds: [IFRAME_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, htmlViewConfig2);

    // Window used to display HTML viewer
    const htmlWindowConfig2: TpzWindowConfig = {
        id: 'html-window-2',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        jsPanelOptions: {
            position: 'left-top 10px 520px',
            panelSize: '500px 400px'
        },
        title: 'Launch',
        childrenIds: [htmlViewConfig2.id]
    };
    TpzApplicationCommander.registerItem(app, htmlWindowConfig2);

    // HTML displayer
    const htmlViewConfig3: any = {
        id: 'html-viewer-3',
        type: HTML_VIEWER_TYPE,
        iframe: true,
        htmlURL: 'https://jupyter.org/try-jupyter/lab/',
        addOnIds: [IFRAME_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, htmlViewConfig3);

    // Window used to display HTML viewer
    const htmlWindowConfig3: TpzWindowConfig = {
        id: 'html-window-3',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        jsPanelOptions: {
            position: 'left-top 520px 10px',
            panelSize: '1000px 500px'
        },
        title: 'Jupyter',
        childrenIds: [htmlViewConfig3.id]
    };
    TpzApplicationCommander.registerItem(app, htmlWindowConfig3);
    // HTML displayer
    const htmlViewConfig4: any = {
        id: 'html-viewer-4',
        type: HTML_VIEWER_TYPE,
        iframe: true,
        //            htmlContent: (div: HTMLDivElement) => { div.innerHTML = "<h1>Hello World</h1>" },
        htmlURL: 'https://www.yworks.com/yed-live/',
        addOnIds: [IFRAME_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, htmlViewConfig4);

    //
    // Window used to display HTML viewer
    const htmlWindowConfig4: TpzWindowConfig = {
        id: 'html-window-4',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        jsPanelOptions: {
            position: 'left-top 1540px 10px',
            panelSize: '500px 800px'
        },
        title: 'YeD',
        childrenIds: [htmlViewConfig4.id]
    };
    TpzApplicationCommander.registerItem(app, htmlWindowConfig4);

    // Complete desktop
    const desktopConfig: TpzDesktopConfig = {
        id: SPAApplication.FRAME_DESKTOP_ID.id,
        type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
        name: SPAApplication.FRAME_DESKTOP_ID.name,
        childrenIds: [
            // labelWindowConfig.id,
            htmlWindowConfig1.id,
            htmlWindowConfig2.id,
            htmlWindowConfig3.id,
            htmlWindowConfig4.id
        ]
    };
    return desktopConfig;

    // const menu: SimpleMenuPlugin = app.getPluginManager().getPluginById(SmisApplication.MAIN_MENU_ID) as SimpleMenuPlugin;
    // if (!menu) {
    //     app.getLogger()?.error("Cannot retrieve menu " + SmisApplication.MAIN_MENU_ID)
    // } else {
    //     menu.

    // }
}
