// import { HelloWorldItemConfig, HelloWorldFactory } from "@tpz-addon/hello-world-addon";
import {
    TpzApplication,
    TpzDesktopConfig,
    ItemConfig,
    TpzWindow,
    TpzWindowConfig,
    TpzWindowFactory,
    TpzApplicationCommander,
    TpzDesktop,
    TpzApplicationTypes
} from 'topaz';

import { SPAApplication } from '../spa-client';

export const BABYLON_ADDON_ID: string = 'babylon-4.2';

// main content
export function create3DDesktop(app: TpzApplication): TpzDesktopConfig {
    app.getFactoryManager().registerItemFactory(new TpzWindowFactory(app));

    // Babylon View (cannot use BabylonViewConfig cause code is not loaded)
    // babylon addon will be loaded using AddOnManager. Babylon must be registered
    app.getAddOnManager().registerLocalAddOn(BABYLON_ADDON_ID, './js/addons/babylon/');
    const babylonViewConfig: ItemConfig = {
        id: 'babylon-displayer',
        css: ['css/babylon.css'],
        //type: "BabylonViewType",
        type: 'BabylonViewType',
        addOnIds: [BABYLON_ADDON_ID] //
    };
    TpzApplicationCommander.registerItem(app, babylonViewConfig);

    // babylon window
    const babylonWindowConfig: TpzWindowConfig = {
        id: 'babylon-window',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [babylonViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 0px 0px',
            panelSize: '400px 200px',
            syncMargins: true,
            // dragit: {
            //     containment: 10,
            //     snap: true
            // },
            setStatus: 'maximized'
        },
        title: 'Babylon 3D'
    };
    TpzApplicationCommander.registerItem(app, babylonWindowConfig);

    // // Hello world configuration (in code: can use typescript interfaces)
    // app.getFactoryManager().registerItemFactory(new HelloWorldFactory(app));
    // let helloWorldConfig: HelloWorldItemConfig = {
    //     id: "hello-world-item",
    //     type: "HelloWorldItemType",
    // };

    // Complete desktop
    const desktopConfig: TpzDesktopConfig = {
        id: SPAApplication.THREED_DESKTOP_ID.id,
        name: SPAApplication.THREED_DESKTOP_ID.name,
        type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
        childrenIds: [babylonWindowConfig.id]
    };

    return desktopConfig;

    // let menu: SimpleMenuPlugin = app.getPluginManager().getPluginById(BasicApplication.MAIN_MENU_ID) as SimpleMenuPlugin;
    // if (!menu) {
    //     app.getLogger()?.error("Cannot retrieve menu " + BasicApplication.MAIN_MENU_ID)
    // } else {
    //     menu.

    // }
}
