import {
    TpzDesktopConfig,
    TimePickerView,
    TimePickerViewConfig,
    TimePickerViewFactory,
    TpzWindowConfig,
    TpzWindow,
    TpzWindowFactory,
    TpzApplicationCommander,
    TpzDesktop,
    TpzApplicationTypes
} from 'topaz';

import { SPAApplication, CESIUM_ADDON_ID, SPACE_CAT_ADDON_ID } from '../spa-client';

// constants defined in space-cat-addon
const RSO_CATALOG_VIEW_TYPE: string = 'RSOCatalogViewType';
const ORBIT_VIEWER_TYPE: string = 'OrbitViewerType';

const HTTP_TLE_ACCESSOR_TYPE: string = 'HttpTLEAccessorType';
const FAKE_RSO2TLE_ACCESSOR_TYPE: string = 'FakeRSO2TLEAccessorType';
const FAKE_RSO_ACCESSOR_TYPE: string = 'FakeRSOAccessorType';
const ORBIT_VIEW_TLE_SUBVIEW_TYPE: string = 'OrbitViewerTLESubViewConfig';
const ORBIT_VIEWER_SELECTOR_VIEW_TYPE: string = 'OrbitViewerSelectorViewType';

const SATCAT_RSO_ACCESSOR_TYPE: string = 'SatCatRSOAccessorType';

export function createMapDesktop(app: SPAApplication): TpzDesktopConfig {
    app.getFactoryManager().registerItemFactory(new TpzWindowFactory(app));
    app.getFactoryManager().registerItemFactory(new TimePickerViewFactory(app));

    //////////////////////// ACCESSORS
    // Fake accessor 1 (RSO)
    let fakeRSOAccessor1Config: any = {
        id: 'fake-rso-accessor-1',
        rsoCount: 5,
        type: FAKE_RSO_ACCESSOR_TYPE,
        addOnIds: [SPACE_CAT_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, fakeRSOAccessor1Config);

    // Fake accessor 2 (RSO)
    let fakeRSOAccessor2Config: any = {
        id: 'fake-rso-accessor-2',
        rsoCount: 10,
        type: FAKE_RSO_ACCESSOR_TYPE,
        addOnIds: [SPACE_CAT_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, fakeRSOAccessor2Config);

    // Fake accessor 1 (TLE)
    let fakeRSO2TLEAccessorConfig: any = {
        id: 'fake-rso2tle-accessor-1',
        type: FAKE_RSO2TLE_ACCESSOR_TYPE,
        rsoAccessorId: fakeRSOAccessor1Config.id,
        accessorIds: [fakeRSOAccessor1Config.id],
        minTLECount: 1,
        maxTLECount: 5,
        addOnIds: [SPACE_CAT_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, fakeRSO2TLEAccessorConfig);

    // TimePicker
    let timePickerViewConfig: TimePickerViewConfig = {
        id: 'time-displayer',
        type: TimePickerView.TIMEPICKER_VIEW_TYPE,
        css: ['css/time-picker.css', 'css/roller.css', 'css/knob.css']
    };
    TpzApplicationCommander.registerItem(app, timePickerViewConfig);

    // // create a selection filter in RSOCatalog (used by RSO->TLE accessor)
    // let selectionRSOFilter: RSOFilter = RSOFilterHelper.createRSOFilter(
    //     'selection-rso-filter',
    //     RSOFilterTypes.ID_SET,
    //     SPAApplication.SATCAT_ACCESSOR_ID,
    //     'selection-rso-accessor'
    // );
    // RSOFilterIdSetHelper.setRSOFilterIdSetParameter(selectionRSOFilter, { '1957-001A  ': true });
    // //RSOFilterIdSetHelper.setRSOFilterIdSetParameter(selectionRSOFilter, { "1957-001A  ": true, "1957-001B  ": true, "1957-002A  ": true, "1999-025EZJ": true, "2020-064B  ": true, "2020-064A  ": true, "2020-063G  ": true, "2020-063A  ": true });
    // // app.getRSOCatalogPlugin().addOrUpdateRSOFilter(selectionRSOFilter);

    let timePickerWindowConfig: TpzWindowConfig = {
        id: 'time-module',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [timePickerViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 785px 625px',
            panelSize: '700px 140px'
        },
        title: 'Time Picker'
    };

    TpzApplicationCommander.registerItem(app, timePickerWindowConfig);

    // RSO Catalog view
    let rsoCatalogViewConfig: any = {
        id: 'rso-catalog-view',
        type: RSO_CATALOG_VIEW_TYPE,
        //        accessorIds: [SmisApplication.RSO_CATALOG_ACCESSOR_ID],
        rsoCatalogId: SPAApplication.RSO_PLUGIN_ID,
        childrenIds: [],
        addOnIds: [SPACE_CAT_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, rsoCatalogViewConfig);

    // Module for RSOList table displayer
    let rsoCatalogWindowConfig: TpzWindowConfig = {
        id: 'rso-list-window',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [rsoCatalogViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 780px 15px',
            panelSize: '750px 540px'
        },
        title: 'Catalog'
    };
    TpzApplicationCommander.registerItem(app, rsoCatalogWindowConfig);

    // // This catalog is inserted in RSOAccessor list as source of RSOFilters
    // const satCatCatalogAccessorConfig: any = {
    //     id: SPAApplication.SATCAT_ACCESSOR_ID,
    //     type: SATCAT_RSO_ACCESSOR_TYPE,
    //     url: 'http://localhost:6010/satcat-celestrak'
    // };
    // TpzApplicationCommander.registerItem(app, satCatCatalogAccessorConfig);
    // Galileo accessor
    // let galileoTLEAccessorConfig: any = {
    //     id: 'galileo-accessor',
    //     type: HTTP_TLE_ACCESSOR_TYPE,
    //     //        url: "http://localhost:6010/satcat-celestrak"
    //     // url: "http://localhost:6010/new-celestrak"
    //     // url: "http://localhost:6010/catalog-test"
    //     // url: "http://localhost:6010/weather-celestrak"
    //     url: 'http://www.tle.info/data/galileo.txt'
    // };

    // // RSO -> TLE converter accessor
    // let selectionRSOTLEAccessorConfig: any = {
    //     id: 'selection-rso5tle-accessor',
    //     type: FAKE_RSO2TLE_ACCESSOR_TYPE,
    //     rsoAccessorId: galileoTLEAccessorConfig.outputRSOAccessorId,
    //     minTLECount: 1,
    //     maxTLECount: 1
    // };
    // TpzApplicationCommander.registerItem(app, selectionRSOTLEAccessorConfig);

    // Cesium view
    let cesiumViewConfig: any = {
        id: 'cesium-view',
        type: ORBIT_VIEWER_TYPE,
        accessToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0YzNhNTczNS00ZmIxLTQyZGYtYWI3YS01NDNkNWFlZmFjOGUiLCJpZCI6NzEzMiwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU0ODM0NDQ3M30.qES_ytH5uEcBY1AIaGOYom0e5gNrYbodreMhSF_BCFs',
        assetId: 3,
        accessorIds: [fakeRSOAccessor1Config.id],
        addOnIds: [SPACE_CAT_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, cesiumViewConfig);

    // Cesium TLE Sub view
    let cesiumTLEViewConfig: any = {
        id: 'cesium-tle-view',
        type: ORBIT_VIEW_TLE_SUBVIEW_TYPE,
        orbitViewerId: cesiumViewConfig.id,
        tleAccessorId: fakeRSO2TLEAccessorConfig.id,
        iconURL: './images/unknown.png',
        iconScale: 1,
        pointCount: 10,
        displayRSOPosition: true,
        displayAverageRSOTrack: true,
        displayRSOTracks: true,
        displayPositionLinks: true,
        accessorIds: [fakeRSO2TLEAccessorConfig.id],
        addOnIds: [SPACE_CAT_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, cesiumTLEViewConfig);

    // window for Cesium view
    let cesiumWindowConfig: TpzWindowConfig = {
        id: 'cesium-view-window',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [cesiumViewConfig.id, cesiumTLEViewConfig.id],
        jsPanelOptions: {
            position: 'left-top 15px 15px',
            panelSize: '750px 750px'
        },
        title: 'Orbit Viewer'
    };
    TpzApplicationCommander.registerItem(app, cesiumWindowConfig);

    // Orbit Viewer Selector View Config
    let orbitSelectorViewConfig: any = {
        id: 'orbit-selector-view-config',
        type: ORBIT_VIEWER_SELECTOR_VIEW_TYPE,
        accessorIds: [],
        rsoAccessorId: fakeRSOAccessor1Config.id,
        addOnIds: [SPACE_CAT_ADDON_ID]
    };
    TpzApplicationCommander.registerItem(app, orbitSelectorViewConfig);

    // Orbit Viewer Selector Window Config
    let orbitSelectorWindowConfig: TpzWindowConfig = {
        id: 'orbit-selector-window',
        type: TpzApplicationTypes.TPZ_WINDOW_TYPE,
        childrenIds: [orbitSelectorViewConfig.id],
        jsPanelOptions: {
            position: '15px 30px',
            panelSize: '400px 140px'
        },
        title: 'Orbit Viewer Selector View'
    };
    TpzApplicationCommander.registerItem(app, orbitSelectorWindowConfig);

    // Complete desktop
    const desktopConfig: TpzDesktopConfig = {
        id: SPAApplication.CARTO_DESKTOP_ID.id,
        name: SPAApplication.CARTO_DESKTOP_ID.name,
        type: TpzApplicationTypes.TPZ_DESKTOP_TYPE,
        childrenIds: [
            timePickerWindowConfig.id,
            rsoCatalogWindowConfig.id,
            orbitSelectorWindowConfig.id,
            cesiumWindowConfig.id,
            fakeRSOAccessor1Config.id,
            fakeRSOAccessor2Config.id
        ]
        // childrenIds: [timePickerWindowConfig.id, cesiumWindowConfig.id]
    };

    return desktopConfig;
}

// // Galileo Map displayer
// let selectionOLAddonConfig: OlTLEViewSubItemConfig = {
//     id: "selection-ol-tle-addon",
//     type: OlTLEViewSubItem.TLE_OL_ADDON_TYPE,
//     tleAccessorId: selectionRSOTLEAccessorConfig.id,
//     subItemParentViewId: olMapViewConfig.id,
//     olStyle: {
//         ...defaultOlStyleDescriptor,
//         iconURL: "images/business/satellite2.png",
//         iconScale: 0.1,
//         lineColor: "rgba(55,220,40,0.5)",
//         lineWidth: 1
//     },
//     childrenIds: [
//         selectionRSOTLEAccessorConfig.id,
//         olMapViewConfig.id,
//     ]
// };
// TpzApplicationCommander.registerItem(app, selectionOLAddonConfig);

// // Cesium TLE Addon
// let selectionCesiumAddonConfig: CesiumTLEAddonConfig = {
//     id: "selection-cesium-tle-addon",
//     type: CesiumTLE.TLE_CESIUM_ADDON_TYPE,
//     tleAccessorId: selectionRSOTLEAccessorConfig.id,
//     subItemParentViewId: cesiumViewConfig.id,
//     iconURL: './images/business/satellite2.png',
//     iconScale: 0.1,
//     childrenIds: [
//         selectionRSOTLEAccessorConfig.id,
//         cesiumViewConfig.id,
//     ],

// };
// TpzApplicationCommander.registerItem(app, selectionCesiumAddonConfig);

// // Galileo Map displayer
// let galileoAddonConfig: OlTLEViewSubItemConfig = {
//     id: "galileo-addon",
//     type: OlTLEViewSubItem.TLE_OL_ADDON_TYPE,
//     tleAccessorId: galileoTLEAccessorConfig.id,
//     olViewId: olMapViewConfig.id,
//     olStyle: {
//         ...defaultOlStyleDescriptor,
//         iconURL: "images/business/satellite2.png",
//         iconScale: 0.1,
//         lineColor: "rgba(55,220,40,0.5)",
//         lineWidth: 1
//     }

// };

// // Ground segment Map displayer
// let groundSegmentAddonConfig: OlGeoViewSubItemConfig = {
//     id: "ground-segment-addon",
//     type: OlGeoViewSubItem.GEO_OL_ADDON_TYPE,
//     geoAccessorId: null,
//     subItemParentViewId: olMapViewConfig.id,
//     olStyle: {
//         ...defaultOlStyleDescriptor,
//         iconURL: "images/business/antenna.png",
//         iconScale: 0.5,
//         lineColor: "rgba(55,220,40,0.5)",
//         lineWidth: 1,
//         textColor: "rgba(0,0,0,1)"
//     },
//     childrenIds: [olMapViewConfig.id]
// };
// TpzApplicationCommander.registerItem(app, groundSegmentAddonConfig);

// // Galileo Map displayer
// let countriesBorderAddonConfig: OlGeoJsonViewSubItemConfig = {
//     id: "borders-addon",
//     type: OlGeoJsonViewSubItem.GEOJSON_OL_ADDON_TYPE,
//     subItemParentViewId: olMapViewConfig.id,
//     geoJsonURL: "data/geojson/countries.geojson",
//     olStyle: {
//         ...defaultOlStyleDescriptor,
//         fillColor: "rgba(255,250,240,0.02)",
//         lineColor: "rgba(55,20,240,0.8)"
//     },
//     childrenIds: [olMapViewConfig.id]
// };
// TpzApplicationCommander.registerItem(app, countriesBorderAddonConfig);

// // Cesium TLE Addon
// let cesiumGalileoTLEAddonConfig: CesiumTLEAddonConfig = {
//     id: "cesium-galileo-tle-addon",
//     type: CesiumTLEAddon.TLE_CESIUM_ADDON_TYPE,
//     tleAccessorId: galileoTLEAccessorConfig.id,
//     cesiumViewId: cesiumViewConfig.id,
//     iconURL: './images/business/satellite2.png',
//     iconScale: 0.1,

// };
